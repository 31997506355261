import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "migration-guide"
    }}>{`Migration Guide`}</h1>
    <p>{`This document guides you through the migration from Offers API v1 to Offers API v2.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mapping-v1-endpoints-to-v2-endpoints"
        }}>{`Mapping v1 endpoints to v2 endpoints`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#v1-endpoints-that-do-not-have-a-corresponding-v2-replacement"
        }}>{`V1 endpoints that do not have a corresponding v2 replacement`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#requests-to-v2"
        }}>{`Requests to v2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#request-to-trip-pattern"
        }}>{`Request to trip-pattern`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#request-to-searchzones"
        }}>{`Request to search/zones`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#request-to-searchstop-places"
        }}>{`Request to search/stop-places`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#user-profiles"
        }}>{`User profiles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#recommendations"
        }}>{`Recommendations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#special-headers"
        }}>{`Special headers`}</a></li>
    </ul>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`The v1 to v2 transition is mainly about data structure changes to reduce the complexity of the API.
All search end points now have the same response type, which is a heavily summarised version of the offer with `}<inlineCode parentName="p">{`OfferSummary`}</inlineCode>{`, `}<inlineCode parentName="p">{`OptionalProducts`}</inlineCode>{`, `}<inlineCode parentName="p">{`Recommendations`}</inlineCode>{` and `}<inlineCode parentName="p">{`UnavailableProducts`}</inlineCode>{`.
The goal was to simplify the model for clients that do not need the expansive structure and information that is present for order, and settlement purposes.
For back end systems that still need this information, the complete structure is available through the offer retrieval end point.`}</p>
    <p>{`Most v1 endpoints have a `}<a parentName="p" {...{
        "href": "#mapping-v1-endpoints-to-v2-endpoints"
      }}>{`corresponding`}</a>{` v2 endpoint.`}</p>
    <p>{`Some v1 endpoints `}<a parentName="p" {...{
        "href": "#v1-endpoints-that-do-not-have-a-corresponding-v2-replacement"
      }}>{`do not have a v2 replacement`}</a>{`.`}</p>
    <p>{`The search/graphql endpoint is now used internally to generate offers for a trip pattern. This results in a `}<a parentName="p" {...{
        "href": "#request-to-trip-pattern"
      }}>{`cleaner request body to search/trip-pattern`}</a>{`.`}</p>
    <p>{`The data retrieved from the `}<a parentName="p" {...{
        "href": "#v1-endpoints-that-do-not-have-a-corresponding-v2-replacement"
      }}>{`search/extension/*`}</a>{` endpoints is available by passing an additional property, `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{` to the v2 search endpoints.`}</p>
    <p>{`Behaviour has changed in some places, see `}<a parentName="p" {...{
        "href": "#user-profiles"
      }}>{`user profiles`}</a>{` and `}<a parentName="p" {...{
        "href": "#recommendations"
      }}>{`recommendations`}</a>{`.`}</p>
    <h2 {...{
      "id": "specific-model-changes"
    }}>{`Specific model changes`}</h2>
    <h3 {...{
      "id": "products"
    }}>{`Products`}</h3>
    <p>{`An offer can contain preassigned products that are mandatory to buy if the offer is bought, and optional products that can be selected and bought together with the preassigned products.
In previous versions of the API, these two types of products used the same model. In this version of the API, these models are split into two different types to only include necessity fields.
In contrast to optional products, preassigned products do not have a selectableId, price or availability information, as this is redundant or included by the same fields on the offer.
Preassigned products are found in the offer as before, while distinct optional products lie beside the offers and are referenced by selectable id in the list of optional products for an offer.
This way duplicate optional products are not presented, but reusable by different offers in the same response.`}</p>
    <h3 {...{
      "id": "geographicalvalidity"
    }}>{`GeographicalValidity`}</h3>
    <p>{`All information about geographical validity for a product or the entirety of the offer is grouped in the GeographicalValidity type.
GeographicalValidity on the Offer level describes the combined geographical validity for all the preassigned products the Offer provides.
GeographicalValidity on preassigned or optional level (product level) describes the geographical validity for that specific product in the context of the offer that provides it.
This information includes ServiceJourney, DatedServiceJourney, ZonalValidity and PointToPointValidity. ServiceJourney private code and original dated ServiceJourney has been omitted.`}</p>
    <h3 {...{
      "id": "price"
    }}>{`Price`}</h3>
    <p>{`Price is now given as a required field in offers, optional products and discount rights, with amount and currency, but without VAT group.
Price contributions are not present in the structure, but price on optional products and discount rights are in essence the contributing price to the total price of the offer.
The price on offer level includes the price of all preassigned products.`}</p>
    <h3 {...{
      "id": "name"
    }}>{`Name`}</h3>
    <p>{`Description is omitted and name is given as a string with default language nob, where it used to be a list of the TextInLanguage model. Used language can be specified by header.`}</p>
    <h4 {...{
      "id": "properties"
    }}>{`Properties`}</h4>
    <p>{`The most important fields in properties and parameters from products (FareProductConfiguration, ValidableElementConfiguration and FareStructureElementConfiguration),
in addition to condition summary, have been moved to product level with the fields aggregated into the new PropertiesSummary type:`}</p>
    <ul>
      <li parentName="ul">{`Both reserving and accommodations are given as an enum for each ServiceJourney, instead of referring to the entity in Products and requiring a lookup to read the actual value.`}</li>
      <li parentName="ul">{`For each optional or mandatory product, luggage allowances are given as a list of enums in PropertiesSummary, instead as a PricedUsageParameter in UsageParameters.`}</li>
      <li parentName="ul">{`User profiles for an offer is summarised in UserProfileSummary with added name and UserTypeEnum.`}</li>
      <li parentName="ul">{`For groups, description, which user profiles and how many of each user profile that can use the group, is included in the GroupSummary.`}</li>
      <li parentName="ul">{`Fare classes are given as enums in the properties.`}</li>
      <li parentName="ul">{`Carnet is summarised with amount and duration.`}</li>
    </ul>
    <h3 {...{
      "id": "recommendation"
    }}>{`Recommendation`}</h3>
    <p>{`Recommendation types have been unified into one set of types, which means that every search endpoint that supports recommendations uses the same request and response type for
configuring recommendations and recommending which offers to buy. The response types have been flattened to more easily expand with new fields in the future, and the option to
only use a subset of the fields to recommend offers, without constructing several deep types to express the same. Se more information about recommendations `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-recommendations"
      }}>{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "other-model-changes"
    }}>{`Other model changes`}</h3>
    <ul>
      <li parentName="ul">{`Selectable ids have been removed from models that are not optional when reserving offers.`}</li>
      <li parentName="ul">{`DiscountRight has been slimmed down to id, version, price and name.`}</li>
    </ul>
    <h2 {...{
      "id": "new-v2-endpoints"
    }}>{`New v2 endpoints`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/authority/get-offers-v2-authority-id"
            }}>{`/offers/v2/search/authority`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The authority endpoint allows you to request products without geographical limitation. Only a traveller, a travelDate and the id of the authority is needed. See `}<a parentName="td" {...{
              "href": "/pages-offers-docs-guides-v2-search"
            }}>{`search guide`}</a>{` v2 for better explanation`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "mapping-v1-endpoints-to-v2-endpoints"
    }}>{`Mapping v1 endpoints to v2 endpoints`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`API v1`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`API v2`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/getOffer"
            }}>{`/sales/v1/offers/search/offers/{id}`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/offers/getOffersV2"
            }}>{`/offers/v2/{id}`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/getTravellersForOffer"
            }}>{`/sales/v1/offers/search/offers/{id}/travellers`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/offers/getOffersV2TravellersId"
            }}>{`/offers/v2/{id}/travellers`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/searchStopPlaces"
            }}>{`/sales/v1/offers/search/stop-places`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
            }}>{`/offers/v2/search/stop-places`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/searchZones"
            }}>{`/sales/v1/offers/search/zones`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/zones/postOffersV2SearchZones"
            }}>{`/offers/v2/search/zones`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/postTripV1"
            }}>{`/sales/v1/offers/search/trip`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/trip-pattern/postOffersV2SearchTripPattern"
            }}>{`/offers/v2/search/trip-pattern`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/findTripPatternById"
            }}>{`/sales/v1/offers/search/trip/trip-pattern/{id}`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/trip-pattern/getOffersV2TripPatternId"
            }}>{`/offers/v2/trip-pattern/{id}`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/getJourneySpec"
            }}>{`/sales/v1/offers/search/journey-spec/{id}`}</a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/journey-spec/getOffersV2JourneySpecId"
            }}>{`/offers/v2/journey-spec/{id}`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "v1-endpoints-that-do-not-have-a-corresponding-v2-replacement"
    }}>{`V1 endpoints that do not have a corresponding v2 replacement`}</h2>
    <ul>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/postGraphqlRequest"
        }}>{`/sales/v1/offers/search/graphql`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/findLayeredRecommendation"
        }}>{`/sales/v1/offers/search/trip/trip-pattern/{id}/layeredRecommendation`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/postProduct"
        }}>{`/sales/v1/offers/search`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/searchZones"
        }}>{`/sales/v1/offers/search/extensions/zones`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.entur.io/sales/v1/offers/api-docs/swagger.json#/search/searchTripExtensions"
        }}>{`/sales/v1/offers/search/extensions/trip`}</a></li>
    </ul>
    <p>{`Note that the functionality behind `}<inlineCode parentName="p">{`/sales/v1/offers/search/extensions/zones`}</inlineCode>{` and `}<inlineCode parentName="p">{`/sales/v1/offers/search/extensions/trip`}</inlineCode>{` is still available in v2.
Extension offers are returned by `}<inlineCode parentName="p">{`/offers/v2/search/zones`}</inlineCode>{` and `}<inlineCode parentName="p">{`/offers/v2/search/trip-pattern`}</inlineCode>{` when you specify `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{` in the request.`}</p>
    <h1 {...{
      "id": "requests-to-v2"
    }}>{`Requests to v2`}</h1>
    <p>{`For a complete guide to v2's search endpoints, see `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-search"
      }}>{`search guide`}</a>{` `}<br />{`
To request offers that require a specific departure -> use the `}<a parentName="p" {...{
        "href": "#request-to-trip-pattern"
      }}>{`trip-pattern endpoint`}</a>{` `}<br />{`
For offers that do not require a specific departure -> use either the `}<a parentName="p" {...{
        "href": "#request-to-searchzones"
      }}>{`zones`}</a>{` or `}<a parentName="p" {...{
        "href": "#request-to-searchstop-places"
      }}>{`stop-places`}</a>{` endpoint`}</p>
    <h2 {...{
      "id": "request-to-trip-pattern"
    }}>{`Request to trip-pattern`}</h2>
    <p>{`For full documentation on the trip-pattern endpoint, see the `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-search#search-by-trip-pattern"
      }}>{`trip-pattern`}</a>{` chapter in the search guide.`}</p>
    <p>{`URL: `}<a parentName="p" {...{
        "href": "https://api.entur.io/offers/v2/search/trip-pattern"
      }}>{`https://api.entur.io/offers/v2/search/trip-pattern`}</a>{` `}<br />{`
Method: POST `}<br />{`
Request body: `}<inlineCode parentName="p">{`SearchTripPatternRequest`}</inlineCode></p>
    <p>{`The request to v2's trip-pattern is much simpler than the request to v1's trip. The graphql response that was the input to v1's trip endpoint is now executed internally.
Therefore, all you need to request offers from trip-pattern are the following:`}</p>
    <p>{`1) One or more `}<inlineCode parentName="p">{`Traveller`}</inlineCode>{` objects
2) A `}<inlineCode parentName="p">{`tripPattern`}</inlineCode>{` consisting of one or more `}<inlineCode parentName="p">{`legs`}</inlineCode>{`.`}</p>
    <p>{`Additional parameters are:
1) `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{`
2) `}<inlineCode parentName="p">{`recommendationConfig`}</inlineCode>{`
3) `}<inlineCode parentName="p">{`groupConfig`}</inlineCode></p>
    <ExpandablePanel title="Minimal example request to search/trip-pattern" mdxType="ExpandablePanel">
    <pre>{`
{
  "travellers": [
    {
      "userType": "ADULT"
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-03-16",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:548",
        "serviceJourneyId": "VYG:ServiceJourney:1-376-67-202203031718"
      }
    ]
  }
}`}</pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "request-to-searchzones"
    }}>{`Request to search/zones`}</h2>
    <p>{`For full documentation on the zones endpoint, see the `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-search#search-by-zones"
      }}>{`zones`}</a>{` chapter in the search guide.`}</p>
    <p>{`URL: `}<a parentName="p" {...{
        "href": "https://api.entur.io/offers/v2/search/zones"
      }}>{`https://api.entur.io/offers/v2/search/zones`}</a>{` `}<br />{`
Method: POST `}<br />{`
Request body: `}<inlineCode parentName="p">{`SearchZonesRequest`}</inlineCode></p>
    <p>{`Some key differences have been made to the request body to search/zones.`}</p>
    <p>{`1) `}<inlineCode parentName="p">{`existingTicket`}</inlineCode>{` can be specified. This field will generate different offers,
depending on the validity of the zones covered by the existing ticket. `}<br />{`
Note that specifying an existing ticket only works when there is one traveller object in the request.`}</p>
    <p>{`2) The properties `}<inlineCode parentName="p">{`excludeSingleTripOffers`}</inlineCode>{` and `}<inlineCode parentName="p">{`excludeMultiTripOffers`}</inlineCode>{` have been removed.`}</p>
    <p>{`3) RecommendationConfig has changed. See the guide on recommendations `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-recommendations"
      }}>{`here`}</a></p>
    <ExpandablePanel title="Minimal example request to search/zones with existingTicket" mdxType="ExpandablePanel">
    <pre>
        {`
{
    "from": "RUT:TariffZone:1",
    "to": "RUT:TariffZone:2Ø",
    "travelDate": "2022-03-20T11:39:56Z",
    "travellers": [
        {
            "userType": "ADULT"
        }
    ],
    "existingTicket": {
        "id": "RUT:PreassignedFareProduct:Ruter24Hours",
        "zones": [
            "RUT:TariffZone:1"
        ]
    }
}`}
    </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "request-to-searchstop-places"
    }}>{`Request to search/stop-places`}</h2>
    <p>{`For full documentation on the stop-places endpoint, see the `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-search#search-by-stop-places"
      }}>{`stop-places`}</a>{` chapter in the search guide.`}</p>
    <p>{`URL: `}<a parentName="p" {...{
        "href": "https://api.entur.io/offers/v2/search/stop-places"
      }}>{`https://api.entur.io/offers/v2/search/stop-places`}</a>{` `}<br />{`
Method: POST `}<br />{`
Request body: `}<inlineCode parentName="p">{`SearchStopPlacesRequest`}</inlineCode></p>
    <p>{`1) The properties `}<inlineCode parentName="p">{`excludeSingleTripOffers`}</inlineCode>{` and `}<inlineCode parentName="p">{`excludeMultiTripOffers`}</inlineCode>{` have been replaced by the property `}<inlineCode parentName="p">{`offersForWholeTripOnly`}</inlineCode>{`.`}</p>
    <p>{`2) RecommendationConfig has changed. See the guide on recommendations `}<a parentName="p" {...{
        "href": "/pages-offers-docs-guides-v2-recommendations"
      }}>{`here`}</a></p>
    <ExpandablePanel title="Minimal example request to search/stop-places" mdxType="ExpandablePanel">
    <pre>
        {`
{
    "travellers": [
        {
            "userType": "ADULT"
        }
    ],
    "travelDate": "2022-03-24T00:00:00+00",
    "from": "NSR:StopPlace:635",
    "to": "NSR:StopPlace:123",
    "offersForWholeTripOnly": true
}`}
    </pre>
    </ExpandablePanel>
    <h2 {...{
      "id": "user-profiles"
    }}>{`User profiles`}</h2>
    <p>{`The two main characters are the UserProfile and Traveller data types shown above, where UserProfile represents the data model, and Traveller represents the query.
When the Offers service receives a trip request, traveller are matched against user profiles according to a set of rules.`}</p>
    <p>{`The matching process decides which of the available user profiles will have an offer generated.`}</p>
    <p>{`A traveller with age only will select all profiles with a matching age interval and a proofOfIdentity setting of NONE_REQUIRED
or IDENTITY_DOCUMENT. This also includes profiles lacking a min/max age setting.`}</p>
    <p>{`A traveller with userType only will select profiles with a matching userType that also lacks a typeOfConcessionRef.
If a profile with a matching userType is not found, the traveller will select profiles that match a fallback userType for the traveller and lacks a typeOfConcessionRef.
Which fallback profile that eventually gets chosen is based on which fallback profile that matches the traveller and has the higher priority.`}</p>
    <p>{`A traveller with both userType and age will select profiles with a matching userType and a matching age interval. In addition,
it will select fallback profiles with a matching age, and userTypes SENIOR and ADULT will select senior and adult profiles
regardless of age.`}</p>
    <h4 {...{
      "id": "userprofile"
    }}>{`UserProfile`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`netexId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`id of the user profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minAge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the minimum age a traveller can have to qualify for this user profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`maxAge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the maximum age a traveller can have to qualify for this user profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`userType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UserTypeEnum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the user type this user profile is meant for. See the `}<inlineCode parentName="td">{`UserTypeEnum`}</inlineCode>{` for a list of possible values`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`typeOfConcessionRef`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`this parameter is used to restrict certain user profiles from getting selected. For example there are typeOfConcessionRef's on the form ENT:TypeOfConcession:Student and ENT:TypeOfConcession:Military, to ensure that the traveller must be a STUDENT or MILITARY for this user profile to become valid`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`proofOfIdentity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ProofOfIdentityEnum`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`how the traveller should prove that he in fact is eligible to travel under f.ex STUDENT or MILITARY privileges.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`companionProfiles`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Seq`}{`[CompanionProfile]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`used to specify which user profiles can travel along with this user profile. As an example, infants should not be able to travel alone. Therefore, user profiles that can have an infant travel along with it, should have a reference to the an infant companion profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`baseUserProfileRef`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The netex id of the user profile from which this user profile is derived`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "usertype"
    }}>{`UserType`}</h3>
    <p>{`UserTypes can be categorized in two groups:`}</p>
    <h4 {...{
      "id": "1"
    }}>{`1)`}</h4>
    <p>{`INFANT*, CHILD, YOUTH, ADULT.`}</p>
    <p>{`These UserTypes are regulated by age only.`}</p>
    <p>{`*INFANT varies from the other user types in that it does not have any fallbacks other than ANYONE.
The reason is that we want to avoid situations, where INFANT falls back to a user type, that allows other user types to travel along through its companion profile.`}</p>
    <h4 {...{
      "id": "2"
    }}>{`2)`}</h4>
    <p>{`STUDENT, MILITARY`}</p>
    <p>{`These UserTypes usually require some sort of identification in addition to age.
Either IDENTITY_DOCUMENT, or STUDENT_CARD`}</p>
    <h4 {...{
      "id": "3"
    }}>{`3)`}</h4>
    <p>{`SENIOR`}</p>
    <p>{`The SENIOR UserType is used both for travellers aged 67 and above, but also other travellers that
are entitled to travel with a SENIOR ticket. Therefore, matching a traveller with SENIOR using age only,
will only work properly with age greater than 67.`}</p>
    <h3 {...{
      "id": "proofofidentityenum"
    }}>{`ProofOfIdentityEnum`}</h3>
    <p>{`Some UserTypes, MILITARY or STUDENT for example, are required to prove that they are entitled
to offers in those categories. These are the possible values that "proofOfIdentity" can contain.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`ProofOfIdentityEnum`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NONE_REQUIRED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OTHER`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IDENTITY_DOCUMENT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STUDENT_CARD`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "traveller"
    }}>{`Traveller`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`attribute`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`userType`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UserTypeEnum`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`age`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`productIds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Option[Seq`}{`[String]`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`baggageTypes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Option[Seq`}{`[BaggageTypeEnum]`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cappingSpecification`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Option`}{`[CappingSpecification]`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "fallbacks"
    }}>{`Fallbacks:`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Traveller`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pri 1`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pri 2`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pri 3`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Pri 4`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INFANT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INFANT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CHILD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHILD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YOUTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`YOUTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`YOUTH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SENIOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SENIOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STUDENT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`STUDENT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MILITARY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MILITARY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANYONE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ADULT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "the-fallback-mechanism-is-used-to"
    }}>{`The fallback mechanism is used to:`}</h3>
    <p>{`1) Determine the user profile which is the "next best fit" for the traveller, if no user profile for a given product corresponds to the travellers' user type directly.`}</p>
    <p>{`2) Allow the "age" attribute in the traveller object to modulate which user profile eventually gets chosen for the traveller.`}</p>
    <p>{`Example of 1): Product X is only valid for user profiles corresponding to the user types ADULT and SENIOR. When making a request to offers with the user type CHILD, if product X
fits the service journey and is a valid product for the trip-pattern, an offer with product X for user type ADULT is also presented,
among all other offers for the user type CHILD.`}</p>
    <p>{`Example of 2): A user may be a STUDENT in one authority, entitled to travel under a student user profile. In another authority, he may be above the maximum age for the student user profile.
Instead of providing no offers, an offer for the best fit that the user type falls back to is presented. In this case, the user profile selected for the traveller will be a user profile corresponding to the ADULT user type.`}</p>
    <h2 {...{
      "id": "recommendations"
    }}>{`Recommendations`}</h2>
    <p>{`The various recommendation input and output structures of v1 have been consolidated into a common configuration type on input, and a common result type on output.`}</p>
    <p>{`Recommendations can be configured on, and returned by, all endpoints that return the SearchResponse type:`}</p>
    <ul>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/stop-places/postOffersV2SearchStopPlaces"
        }}>{`/offers/v2/search/stop-places`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/zones/postOffersV2SearchZones"
        }}>{`/offers/v2/search/zones`}</a></li>
      <li parentName="ul">{`POST `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/offers#/trip-pattern/postOffersV2SearchTripPattern"
        }}>{`/offers/v2/search/trip-pattern`}</a></li>
    </ul>
    <h4 {...{
      "id": "examples"
    }}>{`Examples`}</h4>
    <ExpandablePanel title="Request to Search/Stop-Places Oslo S - Drammen S" mdxType="ExpandablePanel">
    <pre>
        {`
{
    "from": "NSR:StopPlace:337",
    "to": "NSR:StopPlace:11",
    "travellers": [
        {
            "id": "Adult-1",
            "userType": "ADULT"
        }
    ],
    "travelDate": "2022-06-01T13:03:15.147539+02:00",
    "offersForWholeTripOnly": true,
    "recommendationConfig": {
        "categorySpec": {
            "typesOfRecommendation": [
                "SEMI_FLEXIBLE",
                "CHEAPEST"
            ],
            "durationTypes": [
                "DAY_PASS",
                "WEEKLY_PASS",
                "MONTHLY_PASS",
                "SEASON_TICKET"
            ]
        },
        "ruleSpec": {
            "journeyOrganizeAlgorithm": "SUBSEQUENT_COMBINATIONS",
            "priceComparisonAlgorithm": "TOTAL_PRICE",
            "mixinOffersWithHigherFlexibility": false
        }
    }
}
            `}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Recommendations from Search/Stop-Places Oslo S to Drammen S" mdxType="ExpandablePanel">
    <pre>
        {`
    "recommendations": [
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "MONTHLY_PASS",
            "offersToBuy": [
                {
                    "id": "8a2c7c9c-bd19-43a7-933b-78b1f9204524",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "MONTHLY_PASS",
            "offersToBuy": [
                {
                    "id": "8a2c7c9c-bd19-43a7-933b-78b1f9204524",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "WEEKLY_PASS",
            "offersToBuy": [
                {
                    "id": "fdc8f82b-0ef2-4ba7-a63d-25682b94ba01",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "WEEKLY_PASS",
            "offersToBuy": [
                {
                    "id": "fdc8f82b-0ef2-4ba7-a63d-25682b94ba01",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "SEASON_TICKET",
            "offersToBuy": [
                {
                    "id": "ec249530-3718-4a8f-8424-7d6f39f17ad8",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:11"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "SEASON_TICKET",
            "offersToBuy": [
                {
                    "id": "ec249530-3718-4a8f-8424-7d6f39f17ad8",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        }
    ]
        `}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Request to Search/Zones RUT:TariffZone:1 & RUT:TariffZone:2Ø" mdxType="ExpandablePanel">
    <pre>
        {`
{
    "travellers": [
        {
            "userType": "ADULT",
            "id": "Adult-1"
        }
    ],
    "travelDate": "2022-05-31T16:10:00.000+02:00",
    "from": "RUT:TariffZone:1",
    "to": "RUT:TariffZone:2Ø",
    "recommendationConfig": {
        "categorySpec": {
            "typesOfRecommendation": [
                "SEMI_FLEXIBLE",
                "NON_FLEXIBLE",
                "CHEAPEST"
            ],
            "durationTypes": [
                "DAY_PASS",
                "WEEKLY_PASS",
                "MONTHLY_PASS",
                "SEASON_TICKET"
            ]
        },
        "ruleSpec": {
            "journeyOrganizeAlgorithm": "SUBSEQUENT_COMBINATIONS",
            "priceComparisonAlgorithm": "TOTAL_PRICE",
            "mixinOffersWithHigherFlexibility": false
        }
    }
}
            `}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Recommendations from Search/Zones RUT:TariffZone:1 & RUT:TariffZone:2Ø" mdxType="ExpandablePanel">
    <pre>
        {`
"recommendations": [
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "MONTHLY_PASS",
            "offersToBuy": [
                {
                    "id": "aff337d9-ec34-4445-a4d1-afa8a2998f81",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "MONTHLY_PASS",
            "offersToBuy": [
                {
                    "id": "aff337d9-ec34-4445-a4d1-afa8a2998f81",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "WEEKLY_PASS",
            "offersToBuy": [
                {
                    "id": "c9ea977a-26f8-40b3-89d7-104d71548e2e",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "WEEKLY_PASS",
            "offersToBuy": [
                {
                    "id": "c9ea977a-26f8-40b3-89d7-104d71548e2e",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "durationType": "SEASON_TICKET",
            "offersToBuy": [
                {
                    "id": "ba768ce2-d319-44de-bd28-3d86390a9468",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "SEASON_TICKET",
            "offersToBuy": [
                {
                    "id": "ba768ce2-d319-44de-bd28-3d86390a9468",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "durationType": "DAY_PASS",
            "offersToBuy": [
                {
                    "id": "e7656368-f511-4b82-9950-3b5c06a9ab21",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "zonalValidity": {
                    "zones": [
                        "RUT:TariffZone:1",
                        "RUT:TariffZone:2Ø"
                    ]
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "durationType": "DAY_PASS",
            "offersToBuy": [
                {
                    "id": "e7656368-f511-4b82-9950-3b5c06a9ab21",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        }
    ]`}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Request to Search/Trip-Pattern Oslo S - Bergen S" mdxType="ExpandablePanel">
    <pre>
        {`
{
  "travellers": [
    {
      "id": "Adult-1",
      "userType": "ADULT"
    }
  ],
  "tripPattern": {
    "legs": [
      {
        "travelDate": "2022-06-03",
        "fromStopPlaceId": "NSR:StopPlace:337",
        "toStopPlaceId": "NSR:StopPlace:548",
        "serviceJourneyId": "VYG:ServiceJourney:605-OSL_2842-R"
      }
    ]
  },
  "recommendationConfig": {
    "categorySpec": {
      "typesOfRecommendation": [
        "FLEXIBLE",
        "SEMI_FLEXIBLE",
        "NON_FLEXIBLE",
        "CHEAPEST"
      ],
      "fareClasses": [
        "PREMIUM_CLASS",
        "STANDARD_CLASS",
        "ECONOMY_CLASS",
        "ANY"
      ],
      "facilitySet": [
        "SLEEPER",
        "ANY_FACILITY_SET",
        "COUCHETTE",
        "RECLINING_SEAT"
      ]
    },
    "ruleSpec": {
      "journeyOrganizeAlgorithm": "COMBINATIONS_FROM_OFFERS",
      "priceComparisonAlgorithm": "TOTAL_PRICE",
      "onlyIncludeRecommendedOffers": true,
      "onlyIncludeRecommendationsWithOffersToBuy": true,
      "mixinOffersWithHigherFlexibility": true
    }
  }
}
            `}
    </pre>
    </ExpandablePanel>
    <ExpandablePanel title="Recommendations from Search/Trip-Pattern Oslo S - Bergen S" mdxType="ExpandablePanel">
    <pre>
        {`
    "recommendations": [
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "FLEXIBLE",
            "offersToBuy": [
                {
                    "id": "32c75ca5-900c-46e8-b842-49b5acfd2ffe",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "offersToBuy": [
                {
                    "id": "f9fcb463-96ef-45e7-ac62-ee096b44d392",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "offersToBuy": [
                {
                    "id": "32c75ca5-900c-46e8-b842-49b5acfd2ffe",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "FLEXIBLE",
            "fareClass": "ANY",
            "offersToBuy": [
                {
                    "id": "32c75ca5-900c-46e8-b842-49b5acfd2ffe",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "offersToBuy": [
                {
                    "id": "f9fcb463-96ef-45e7-ac62-ee096b44d392",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "ANY",
            "offersToBuy": [
                {
                    "id": "f9fcb463-96ef-45e7-ac62-ee096b44d392",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "ANY",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "STANDARD_CLASS",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "ANY",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "32c75ca5-900c-46e8-b842-49b5acfd2ffe",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "FLEXIBLE",
            "fareClass": "ANY",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "32c75ca5-900c-46e8-b842-49b5acfd2ffe",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "f9fcb463-96ef-45e7-ac62-ee096b44d392",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "4b17d61f-9e02-474c-822c-2fdcdf912a9d",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "ANY",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "f9fcb463-96ef-45e7-ac62-ee096b44d392",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "SEMI_FLEXIBLE",
            "fareClass": "ANY",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "4b17d61f-9e02-474c-822c-2fdcdf912a9d",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "6fde901a-9e49-4980-a8c1-55b3233b76c7",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "ANY",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "NON_FLEXIBLE",
            "fareClass": "ANY",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "6fde901a-9e49-4980-a8c1-55b3233b76c7",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "STANDARD_CLASS",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "6fde901a-9e49-4980-a8c1-55b3233b76c7",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "ANY",
            "facilitySet": "ANY_FACILITY_SET",
            "offersToBuy": [
                {
                    "id": "a7937ade-0141-434c-8362-3aaf80a3424b",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        },
        {
            "geographicalValidityCovered": {
                "serviceJourneys": [
                    "VYG:ServiceJourney:605-OSL_2842-R"
                ],
                "pointToPointValidity": {
                    "fromPlace": "NSR:StopPlace:337",
                    "toPlace": "NSR:StopPlace:548"
                }
            },
            "typeOfRecommendation": "CHEAPEST",
            "fareClass": "ANY",
            "facilitySet": "COUCHETTE",
            "offersToBuy": [
                {
                    "id": "6fde901a-9e49-4980-a8c1-55b3233b76c7",
                    "numberToBuy": 1,
                    "selectableProductIds": [],
                    "possibleTravellerIds": [
                        [
                            "Adult-1"
                        ]
                    ]
                }
            ]
        }
    ]
            `}
    </pre>
    </ExpandablePanel>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-offers-docs-api-v2-reference"
      }}>{`API v2 Reference`}</a>{` for details.`}</p>
    <h2 {...{
      "id": "special-headers"
    }}>{`Special headers`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Value`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Comment`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ResponseType`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Affects endpoints /search/stop-places, /search/zones, /search/extensions/zones, /search/trip-pattern, /search/extensions/trip-pattern.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`SearchResponse`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`SearchResponse.offers contains OfferSummary elements (default).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`SearchResponseFull`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`SearchResponse.offers contains v1 Offer elements.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      